import { defineStore } from "pinia";
import { computed, ref } from "vue";
import IMe from "@/types/IMe";
import userApi from "@/services/api/userApi";
import { Gender } from "@/enums/Gender";
import { Goal } from "@/enums/Goal";
import { MeasurementSystem } from "@/enums/MeasurementSystem";
import IOnboarding from "@/types/IOnboarding";

export const useUserStore = defineStore(
  "user",
  () => {
    const settings = {
      subscription: {
        failedStatus: "",
      },
    };

    const data = ref<Partial<IMe>>({});
    const accessToken = ref(localStorage.getItem("accessToken"));
    const refreshToken = ref(localStorage.getItem("refreshToken"));
    const rememberMe = ref(false);
    const isMetricSystem = computed(
      () => data.value.measurementSystem === MeasurementSystem.METRIC
    );

    function setTokens(payload: {
      accessToken: string;
      refreshToken: string;
      rememberMe?: boolean;
    }): void {
      refreshToken.value = payload.refreshToken;
      accessToken.value = payload.accessToken;
      if (typeof payload.rememberMe === "boolean") {
        rememberMe.value = payload.rememberMe;
      }
      localStorage.setItem("accessToken", payload.accessToken);
      if (rememberMe.value || localStorage.getItem("refreshToken")) {
        localStorage.setItem("refreshToken", payload.refreshToken);
      }
    }

    function clear() {
      data.value = {};
      accessToken.value = "";
      refreshToken.value = "";
      rememberMe.value = false;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    }

    function getMe() {
      return userApi.getMe().then((res) => {
        data.value = res.data.data;
        return res;
      });
    }

    function onboarding(payload: IOnboarding) {
      return userApi.userOnboarding(payload).then((res) => {
        getMe();
        return res;
      });
    }

    function updateProfile(payload: {
      fullName: string;
      gender: Gender;
      birthDate: string;
      goal: Goal;
      measurementSystem: MeasurementSystem;
    }) {
      return userApi.updateUserProfile(payload).then((res) => {
        getMe();
        return res;
      });
    }

    function updatePassword(payload: {
      password: string;
      newPassword: string;
      passwordRepeat: string;
    }) {
      return userApi.updateUserPassword(payload).then((res) => {
        return res;
      });
    }

    function changeEmail(payload: { token: string }) {
      return userApi.changeUserEmail(payload).then((res) => {
        setTokens(res.data.data);
        return res;
      });
    }

    function creatingRequestToChangePassword(payload: {
      newEmail: string;
      password: string;
      callback: string;
    }) {
      return userApi
        .creatingRequestToChangeUsersPassword(payload)
        .then((res) => {
          return res;
        });
    }

    function getDashboardSettings() {
      return userApi.getUserDashboardSettings().then((res) => {
        return res;
      });
    }

    return {
      data,
      settings,
      isMetricSystem,
      accessToken,
      refreshToken,
      rememberMe,
      onboarding,
      getDashboardSettings,
      updateProfile,
      updatePassword,
      creatingRequestToChangePassword,
      changeEmail,
      getMe,
      setTokens,
      clear,
    };
  },
  {
    persist: true,
  }
);
